<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Would you prefer a long syllabus (more details) or a short and to-the-point (less reading)
        syllabus?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissSurveyQ16',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: 'Long Syllabus', value: 'one'},
        {text: 'Short Syllabus', value: 'two'},
        {
          text: 'A syllabus organized with a short version on one page, with links to relevant topics',
          value: 'three',
        },
        {text: 'A searchable electronic syllabus', value: 'four'},
      ],
    };
  },
};
</script>
<style scoped></style>
